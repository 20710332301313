<template>
  <transition
    :name="animation"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
  >
    <div id="custom-modal-content" v-if="value">
      <div class="modal-background" :style="overlay"></div>
      <div id="custom-modal" overflowed :style="styles">
        <slot :close="close"></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "auto"
    },
    maxWidth: {
      type: String,
      default: "auto"
    },
    animation: {
      type: String,
      default: "zoom-in"
    },
    canCancel: {
      type: [Array, Boolean],
      default: () => {
        return ["escape", "outside"];
      }
    },
    scroll: {
      type: String,
      default: () => {
        return "clip";
      },
      validator: value => {
        return ["clip", "keep"].indexOf(value) >= 0;
      }
    },
    value: {
      type: Boolean,
      default: false
    },
    showOverlay: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if (typeof window !== "undefined") {
      document.addEventListener("keyup", this.keyPress);
    }
  },
  mounted() {
    this.$root.$el.appendChild(this.$el);
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      this.$el && this.$root.$el.removeChild(this.$el);
      document.removeEventListener("keyup", this.keyPress);
    }
  },
  data() {
    return {
      animating: true
    };
  },
  computed: {
    styles() {
      return {
        width: this.width,
        "max-width": this.maxWidth
      };
    },
    cancelOptions() {
      return typeof this.canCancel === "boolean"
        ? this.canCancel
          ? ["escape", "outside"]
          : []
        : this.canCancel;
    },
    overlay() {
      return {
        backgroundColor: this.showOverlay ? "rgba(24,28,33,.5)" : null
      };
    }
  },
  methods: {
    close() {
      this.$emit("close");
      this.$emit("input", false);
    },
    keyPress(event) {
      this.value && event.keyCode === 27 && this.close();
    },
    cancel(method) {
      if (this.cancelOptions.indexOf(method) < 0) return;
      this.close();
    },

    /**
     * Transition after-enter hook
     */
    afterEnter() {
      this.animating = false;
    },
    /**
     * Transition before-leave hook
     */
    beforeLeave() {
      this.animating = true;
    },
    handleScroll() {
      if (typeof window === "undefined") return;

      if (this.scroll === "clip") {
        if (this.value) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style = null;
        }
      }
    }
  },
  watch: {
    value() {
      this.handleScroll();
    }
  }
};
</script>

<style lang="scss">
.zoom-in-enter-active,
.zoom-in-leave-active {
  transition: opacity 150ms cubic-bezier(0.52, 0.02, 0.19, 1.02);
  .animation-content,
  .animation-content {
    transition: transform 150ms cubic-bezier(0.52, 0.02, 0.19, 1.02);
  }
}
.zoom-in-enter,
.zoom-in-leave-active {
  opacity: 0;
  .animation-content,
  .animation-content {
    transform: scale(0.95);
  }
}

#custom-modal-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 40;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  left: 0;
  /* pointer-events: none; */
  transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* z-index: 202; */
  /* outline: none; */
}

.modal-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

#custom-modal {
  overflow: hidden;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  margin: 24px;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: inherit;
}
#custom-modal[overflowed] {
  overflow-y: auto;
  max-height: 90%;
}
</style>
