// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900);"]);
exports.push([module.id, "@import url(https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css);"]);
exports.push([module.id, "@import url(https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css);"]);
// Module
exports.push([module.id, ".container{padding:0!important}.v-application{font-family:Roboto,sans-serif;background:transparent!important}.v-application .v-application--wrap{min-height:auto;margin:5px}._title{width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}._title .btn-close{cursor:pointer}.sucess-message{color:#222!important}", ""]);
// Exports
module.exports = exports;
