<template>
  <v-app>
    <RecoveryPasswrod
      :dialog="show"
      :apiUrl="api"
      :owner="owner"
      :hasToInformOwner="multiOwnerApplication"
      @onCloseRecovery="onCloseModalRecovery"
    />
    <RedefinePassword
      :dialog="redefine"
      :token="token"
      :apiUrl="api"
      :owner="owner"
      :hasToInformOwner="multiOwnerApplication"
      @onCloseRecovery="onCloseModalRecovery"
    />
  </v-app>
</template>

<script>
import Vue from "vue";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import RecoveryPasswrod from "./components/RecoveryPasswrod";
import RedefinePassword from "./components/RedefinePassword";

export default {
  name: "App",
  Vue,
  vuetify,
  i18n,
  components: {
    RecoveryPasswrod,
    RedefinePassword
  },
  props: {
    show: {
      type: Boolean,
      default: () => false
    },
    token: {
      type: String
    },
    api: {
      type: String
    },
    owner: {
      type: String
    },
    locale: {
      type: String
    },
    top: {
      type: String,
      default: function() {
        return "4%";
      }
    },
    left: {
      type: String,
      default: function() {
        return "2%";
      }
    },
    redefine: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    multiOwnerApplication: {
      type: Boolean,
      default: function() {
        return false;
      }
    }
  },
  data: () => ({}),
  computed: {
    styles() {
      return Object.assign(
        { position: "absolute" },
        { width: "56px" },
        { top: this.top, left: this.left }
      );
    }
  },
  watch: {
    locale(v) {
      this.$i18n.locale = v;
    }
  },
  methods: {
    onCloseModalRecovery(a) {
      if (a == false) this.$emit("onCloseRecovery", a);
    }
  }
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900");
@import url("https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css");
@import url("https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css");
.container {
  padding: 0% !important;
}
.v-application {
  font-family: "Roboto", sans-serif;
  background: transparent !important;
  .v-application--wrap {
    min-height: auto;
    margin: 5px;
  }
}
._title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .btn-close {
    cursor: pointer;
  }
}
.sucess-message {
  color: #222 !important;
}
</style>
