<template>
  <v-container>
    <base-modal v-model="dialog" show-overlay width="650px">
      <v-card
        v-if="session != 'REDEFINE_SUCCESS'"
        v-on:keyup.enter="redefinePassword"
      >
        <v-card-title>
          <div class="_title">
            <h3>{{ $t("Title") }}</h3>
            <div class="btn-close" @click="closeDialog()">X</div>
          </div>
        </v-card-title>
        <div style="padding:30px">
          <form @submit.prevent="redefinePassword">
            <div>
              <v-text-field
                :label="$t('Password') + '*'"
                v-model="password"
                maxlength="20"
                :class="classes"
                :rules="passwordRules"
                :type="'password'"
                @blur="onBlur"
                @focus="onFocus"
                @mousedown="onFocus"
                required
                outlined
                dense
              >
              </v-text-field>

              <div class="strength">
                <p
                  class="descBarPasswordStrength"
                  :style="{ color: this.backgroundColorStrength }"
                >
                  {{ $t(passwordStrengthText) }}
                </p>
                <div class="statusBarPasswordStrengthCover">
                  <div
                    class="statusBarPasswordStrength"
                    :style="{
                      background: backgroundColorStrength,
                      width: percentagePasswordStrength + '%'
                    }"
                  ></div>
                </div>
              </div>
            </div>
            <div>
              <v-text-field
                :label="$t('Confirm Password') + '*'"
                v-model="confirmPassword"
                maxlength="20"
                :rules="passwordRules"
                :class="classes"
                :type="'password'"
                @blur="onBlur"
                @focus="onFocus"
                @mousedown="onFocus"
                :error-messages="
                  confirmPassword != '' && confirmPassword != password
                    ? [$t('PasswordDontMatch')]
                    : []
                "
                required
                outlined
                dense
              >
              </v-text-field>
            </div>
          </form>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">{{
            $t("Cancel")
          }}</v-btn>
          <v-btn
            type="submit"
            color="primary"
            text
            @click="redefinePassword()"
            :disabled="checkPassword()"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-card>

      <!-- mensagem de sucesso na redefinição de senha -->
      <v-card v-if="session == 'REDEFINE_SUCCESS'">
        <v-card-title>
          <div class="_title">
            <h3>{{ $t("Title") }}</h3>
            <div class="btn-close" @click="closeDialog()">X</div>
          </div>
        </v-card-title>
        <v-card-subtitle class="sucess-message">
          <h2>{{ $t("PasswordSuccessRecovery") }}</h2>
        </v-card-subtitle>
        <v-divider></v-divider>
      </v-card>
    </base-modal>
  </v-container>
</template>

<script>
import BaseModal from "./BaseModal";
import axios from "../plugins/axios";

export default {
  components: {
    BaseModal
  },
  computed: {
    classes() {
      return this.isFocused ? ["v-input--is-focused", "primary--text"] : [];
    }
  },
  data() {
    return {
      isFocused: false,
      session: "REDEFINE",
      passwordStrength: 0,
      password: "",
      confirmPassword: "",
      passwordRules: [
        v => !!v || this.$t("Password is required"),
        v =>
          (/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/.test(
            v
          ) &&
            this.password.length < 21) ||
          this.$t("Try a strong password")
      ],
      backgroundColorStrength: "grey",
      percentagePasswordStrength: 0,
      passwordStrengthText: "Password Strength",
      url: this.apiUrl,
      clients: {},
      listBD: [],
      ownerDatabase: ""
    };
  },
  methods: {
    findOwnerBD() {
      this.listBD.forEach(item => {
        if (this.clients[item].includes(this.owner.toUpperCase()))
          this.ownerDatabase = item;
      });
    },
    async getClients() {
      const urlClients = "https://auth-api.saas-solinftec.com/v2/jdbc/";
      await axios
        .get(urlClients)
        .then(response => {
          this.clients = response.data;
          this.listBD = Object.keys(this.clients);
        })
        .catch(error => console.error("Error: ", error));
    },
    showModal() {
      this.dialog = true;
    },
    async redefinePassword() {
      if (this.hasToInformOwner) {
        await this.getClients();
        await this.findOwnerBD();
        this.url = `https://sgpa-api-br.saas-solinftec.com/${this.ownerDatabase}`;
      }
      /**
       * função para enviar um email com a nova senha
       * verifica usuario e email correspondem
       */
      if (
        this.confirmPassword != "" &&
        this.confirmPassword == this.password &&
        this.password.length >= 8
      ) {
        axios
          .post(this.url + "/user/redefine-password", {
            senhaNova: this.password,
            senhaNovaConfirmacao: this.confirmPassword
          })
          .then(response => {
            if (response.data == 200) this.session = "REDEFINE_SUCCESS";
          });
      }
    },
    checkPassword() {
      return (
        this.confirmPassword.length == 0 ||
        !(this.password.length > 0 && this.password == this.confirmPassword) ||
        !/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/.test(
          this.password
        ) ||
        !(this.password.length > 0 && this.password.length < 21)
      );
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    closeDialog() {
      this.dialog = false;
    },
    passwordStrengthTest() {
      var strength = 0;

      if (this.password.length >= 1 && this.password.length <= 9) {
        strength += 5;
      } else if (this.password.length > 9) {
        strength += 25;
      }
      if (this.password.match(/[a-z]+/)) {
        strength += 10;
      }
      if (this.password.match(/[A-Z]+/)) {
        strength += 20;
      }
      if (this.password.match(/d+/)) {
        strength += 20;
      }
      if (this.password.match(/\W+/)) {
        strength += 25;
      }
      let startCount = 0,
        strengthRepeatedLoop = false,
        strengthSizeLoop = this.password.length,
        strengthSequncialLoop = false;

      do {
        strengthRepeatedLoop =
          this.password[startCount] == this.password[startCount + 1];

        let nb1 = Number(this.password[startCount]);
        let nb2 = Number(this.password[startCount + 1]);
        if (!isNaN(nb1) && !isNaN(nb2) && (nb1 + 1 == nb2 || nb1 - 1 == nb2))
          strengthSequncialLoop = true;
        else strengthSequncialLoop = false;

        strengthSizeLoop--;
        startCount++;

        if (strengthRepeatedLoop) strength -= 8;
        if (strengthSequncialLoop) strength -= 5;
      } while (strengthSizeLoop >= 2);

      strength = this.password.length > 0 && strength <= 0 ? 1 : strength;

      if (strength <= 0) this.passwordStrength = 0;
      else if (strength <= 30) this.passwordStrength = 1;
      else if (strength > 30 && strength <= 60) this.passwordStrength = 2;
      else if (strength > 60 && strength <= 85) this.passwordStrength = 3;
      else if (strength > 85) this.passwordStrength = 4;

      this.percentagePasswordStrength = this.passwordStrength * 25;

      switch (this.passwordStrength) {
        case 1:
          this.backgroundColorStrength = "red";
          this.passwordStrengthText = "Weak";
          break;
        case 2:
          this.backgroundColorStrength = "orange";
          this.passwordStrengthText = "Medium";
          break;
        case 3:
          this.backgroundColorStrength = "LightGreen";
          this.passwordStrengthText = "Good";
          break;
        case 4:
          this.backgroundColorStrength = "Green";
          this.passwordStrengthText = "Great!";
          break;
        default:
          this.backgroundColorStrength = "grey";
          this.passwordStrengthText = "Password Strength";
      }
    }
  },
  watch: {
    apiUrl(v) {
      if (v) this.url = v;
    },
    dialog(v) {
      if (v == false) {
        this.emailToConfirm = "";
        this.username = "";
        this.email = "";
        this.validUser = true;
        this.session = "REDEFINE";
      }
      this.$emit("onCloseRecovery", this.dialog);
    },
    password() {
      this.passwordStrengthTest();
    },
    token(v) {
      axios.defaults.headers.common["X-Auth-Token"] = v;
    }
  },
  props: {
    dialog: {
      type: Boolean
    },
    apiUrl: {
      type: String
    },
    token: {
      type: String
    },
    hasToInformOwner: {
      type: Boolean
    },
    owner: {
      type: String
    }
  }
};
</script>

<style lang="scss">
.headline {
  font-size: 15px !important;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal !important;
  font-family: "Roboto", sans-serif !important;
}
.v-toolbar__title {
  font-size: 1.25rem;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.v-input--is-focused i {
  color: #1976d2 !important;
}
.primary--text {
  color: #1867c0 !important;
  caret-color: #1867c0 !important;
}
.error--text {
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
}
.primary {
  background-color: #1867c0 !important;
  border-color: #1867c0 !important;
}
.success {
  color: #4caf50 !important;
}
.strength {
  text-align: left;
  width: 200px;
  margin-bottom: 10px;
}
.strength .statusBarPasswordStrengthCover {
  border-radius: 6px;
  border: 1px solid gray;
}
.strength .statusBarPasswordStrength {
  // width: 45%;
  height: 8px;
  border-radius: 4px;
  // background-color: orange;
}
.strength p.descBarPasswordStrength {
  font-size: 12px;
  margin-bottom: -0px;
}
</style>
