import Vue from "vue";
import Vuetify, {
  VApp,
  VContent,
  VContainer,
  VRow,
  VCol,
  VBtn,
  VIcon,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VSpacer,
  VTabs,
  VForm,
  VSnackbar,
  VTextField,
  VDialog,
  VDivider
} from "vuetify/lib";

Vue.use(Vuetify, {
  components: {
    VApp,
    VContent,
    VContainer,
    VRow,
    VCol,
    VBtn,
    VIcon,
    VCard,
    VCardActions,
    VCardText,
    VCardTitle,
    VSpacer,
    VTabs,
    VForm,
    VSnackbar,
    VTextField,
    VDialog,
    VDivider
  }
});

export default new Vuetify({
  theme: {
    disable: false,
    themes: {
      light: {
        primary: "#43A9B6",
        success: "#68a753",
        error: "#924949",
        info: "#4c7ca8"
      }
    }
  },
  icons: {
    iconfont: "mdi" // default - only for display purposes
  }
});
