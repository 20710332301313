<template>
  <v-container>
    <base-modal v-model="dialog" show-overlay width="650px">
      <v-card v-if="session != 'RECOVERY_SUCCESS'">
        <v-card-title>
          <div class="_title">
            <h3>{{ $t("Title") }}</h3>
            <div class="btn-close" @click="closeDialog()">X</div>
          </div>
        </v-card-title>
        <div style="padding:30px">
          <form v-on:submit.prevent="onSubmit">
            <div>
              <v-text-field
                outlined
                dense
                :label="$t('Username') + '*'"
                v-model="username"
                :rules="[usernameRules.required]"
                :class="classes"
                @blur="onBlur"
                @focus="onFocus"
                @mousedown="onFocus"
                :disabled="emailToConfirm.length > 0"
                :error-messages="userMessages"
                required
              >
              </v-text-field>
            </div>
            <div style="width: 100%;" v-if="emailToConfirm.length > 0">
              <p>{{ $t("confirmYourEmail") }}: {{ emailToConfirm }}</p>
              <v-text-field
                outlined
                dense
                :label="$t('Email') + '*'"
                v-model="email"
                :rules="emailRules"
                :class="classes"
                @blur="onBlur"
                @focus="onFocus"
                @mousedown="onFocus"
                :error-messages="this.invalidEmail ? [$t('InvalidEmail')] : []"
                required
              >
              </v-text-field>
            </div>
          </form>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">{{
            $t("Cancel")
          }}</v-btn>
          <v-btn
            type="submit"
            color="primary"
            text
            @click="verify()"
            v-if="emailToConfirm.length == 0"
            >{{ $t("Check") }}</v-btn
          >
          <v-btn
            type="submit"
            color="primary"
            text
            @click="forgotePassword()"
            v-if="emailToConfirm.length > 0"
            >{{ $t("Send") }}</v-btn
          >
        </v-card-actions>
      </v-card>

      <!-- mensagem de sucesso na envio do email -->
      <v-card v-if="session == 'RECOVERY_SUCCESS'">
        <v-card-title>
          <div class="_title">
            <h3>{{ $t("Title") }}</h3>
            <div class="btn-close" @click="closeDialog()">X</div>
          </div>
        </v-card-title>
        <v-card-subtitle class="sucess-message">
          <h2>{{ $t("SuccessEmailSend") }}</h2>
          <p>{{ $t("SuccessMessageEmailSent") }}</p>
        </v-card-subtitle>
        <v-divider></v-divider>
      </v-card>
    </base-modal>
  </v-container>
</template>

<script>
import BaseModal from "./BaseModal";
import axios from "../plugins/axios";

export default {
  components: {
    BaseModal
  },
  computed: {
    classes() {
      return this.isFocused ? ["v-input--is-focused", "primary--text"] : [];
    },
    userMessages() {
      const msgs = [];

      if (this.invalidUser) msgs.push(this.messageInvalidUser);
      if (this.invalidOwner) msgs.push(this.$t("invalidOwner"));

      return msgs;
    }
  },
  data() {
    return {
      isFocused: false,
      session: "RECOVERY",
      username: "",
      invalidUser: false,
      invalidOwner: false,
      usernameRules: {
        required: v => !!v || "Required.",
        valid: () => this.$t("InvalidUserName")
      },
      messageInvalidUser: "",
      email: "",
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+/.test(v) || this.$t("E-mail must be valid")
      ],
      invalidEmail: false,
      emailToConfirm: "",
      url: this.apiUrl,
      cdUsuario: "",
      clients: {},
      listBD: [],
      ownerDatabase: ""
    };
  },
  methods: {
    onSubmit() {
      if (this.emailToConfirm.length == 0) this.verify();
      else if (this.emailToConfirm.length > 0) this.forgotePassword();
    },
    showModal() {
      this.dialog = true;
    },
    findOwnerBD() {
      this.listBD.forEach(item => {
        if (this.clients[item].includes(this.owner.toUpperCase()))
        this.ownerDatabase = item === "prdteste" ? "slfprdteste" : item;
      });
    },
    async getClients() {
      const urlClients = "https://auth-api.saas-solinftec.com/v2/jdbc/";
      await axios
        .get(urlClients)
        .then(response => {
          this.clients = response.data;
          this.listBD = Object.keys(this.clients);
        })
        .catch(error => console.error("Error: ", error));
    },
    async verify() {
      let username = this.username;
      if (this.hasToInformOwner) {
        await this.getClients();
        await this.findOwnerBD();
        this.url = `https://sgpa-api-br.saas-solinftec.com/${this.ownerDatabase}`;
        username = this.username.split("@")[0];
      }

      // verifica se usuario existe e retorna o email para confirmar conta
      axios.defaults.headers.common["Client-Id"] = this.owner;

      axios
        .post(this.url + "/user/check-user", {
          cdUsuario: username
        })
        .then(response => {
          if (response.data == "INVALID_USER") {
            this.invalidUser = true;
            this.messageInvalidUser = this.$t("Invalid Username");
            this.emailToConfirm = "";
          } else if (response.data == "EMAIL_IS_EMPTY") {
            this.invalidUser = true;
            this.messageInvalidUser = this.$t("emailNotRegistred");
            this.emailToConfirm = "";
          } else {
            this.emailToConfirm = response.data.email;
            this.invalidUser = false;
          }
        });
    },
    forgotePassword() {
      /**
       * função para enviar um email com a nova senha
       * verifica usuario e email correspondem
       */
      axios
        .post(this.url + "/user/forget-password", {
          cdUsuario: this.cdUsuario,
          email: this.email
        })
        .then(response => {
          if (response.data == 200) this.session = "RECOVERY_SUCCESS";
          else {
            this.invalidEmail = true;
          }
        });
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    closeDialog() {
      this.dialog = false;
    }
  },
  watch: {
    apiUrl(v) {
      if (v) this.url = v;
    },
    dialog(v) {
      if (v == false) {
        this.emailToConfirm = "";
        this.username = "";
        this.email = "";
        this.invalidUser = false;
        this.session = "Recovery";
      }
      this.$emit("onCloseRecovery", this.dialog);
    },
    username() {
      this.invalidUser = false;
      if (this.hasToInformOwner) {
        const owner = this.username.split("@");
        if (!owner[1]) {
          this.invalidOwner = true;
        } else {
          this.owner = owner[1];
          this.cdUsuario = owner[0];
          this.invalidOwner = false;
        }
      } else {
        this.cdUsuario = this.username;
      }
    },
    email() {
      this.invalidEmail = false;
    }
  },
  props: {
    dialog: {
      type: Boolean
    },
    apiUrl: {
      type: String
    },
    owner: {
      type: String
    },
    hasToInformOwner: {
      type: Boolean
    }
  },
  mounted() {
    if (this.apiUrl) {
      this.url = this.apiUrl;
    }
  }
};
</script>

<style lang="scss">
.headline {
  font-size: 15px !important;
  font-weight: 400;
  letter-spacing: normal !important;
  font-family: "Roboto", sans-serif !important;
}
.v-input--is-focused i {
  color: #1976d2 !important;
}
.primary--text {
  color: #1867c0 !important;
  caret-color: #1867c0 !important;
}
.error--text {
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
}
.primary {
  background-color: #1867c0 !important;
  border-color: #1867c0 !important;
}
.success {
  color: #4caf50 !important;
}
</style>
